<template>
    <ion-page>
        <!-- <div class="full-height" style="position: relative"> -->
        <ThanhTieuDeVue TieuDe="Thông tin cá nhân"
            ><template #after><div></div></template
        ></ThanhTieuDeVue>
        <ion-content>
            <div slot="fixed" style="width: 100%" class="white--bg">
                <div class="container">
                    <div class="column align-center">
                        <div class="title font-16">Ảnh đại diện</div>
                        <div style="max-width: 105px; max-height: 105px">
                            <div
                                class="
                                    anh-dai-dien-style
                                    row
                                    justify-center
                                    align-center
                                "
                                @click="getAnh"
                            >
                                <img
                                    :src="url"
                                    alt=""
                                    width="100%"
                                    v-if="url"
                                />
                            </div>
                            <!-- <i
                                class="mdi mdi-camera"
                                style="
                                    position: absolute;
                                    bottom: -15px;
                                    left: 46.5%;
                                    opacity: 0.5;
                                "
                            ></i> -->
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
            </div>
            <ion-list style="margin-top: 200px">
                <ion-item>
                    <ion-label>Tên tài khoản</ion-label>
                    <ion-label slot="end">{{
                        $Core.AuthApi.TokenParsed
                            ? $Core.AuthApi.TokenParsed.preferred_username
                            : ""
                    }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>Họ và tên</ion-label>
                    <ion-label slot="end">{{ data.hoTen }}</ion-label>
                </ion-item>
                <ion-item
                    :router-link="{
                        path: '/Ca-Nhan/ngay-sinh',
                        query: { data: data.ngaySinh },
                    }"
                    button
                    detail
                >
                    <ion-label>Ngày sinh</ion-label>
                    <ion-label slot="end">{{
                        data.ngaySinh
                            ? moment(data.ngaySinh).format("DD/MM/yyyy")
                            : null
                    }}</ion-label>
                </ion-item>
                <ion-item
                    :router-link="{
                        path: '/Ca-Nhan/so-dien-thoai',
                        query: { data: data.soDienThoai },
                    }"
                    button
                    detail
                >
                    <ion-label>Số điện thoại</ion-label>
                    <ion-label slot="end"> {{ data.soDienThoai }}</ion-label>
                </ion-item>
                <ion-item
                    :router-link="{
                        path: '/Ca-Nhan/email',
                        query: { data: data.email },
                    }"
                    button
                    detail
                >
                    <ion-label>Email</ion-label>
                    <ion-label slot="end"> {{ data.email }}</ion-label>
                </ion-item>
                <ion-item
                    :router-link="{
                        path: '/Ca-Nhan/dia-chi-thuong-tru',
                        query: {
                            idTinh: data.idTinh,
                            idHuyen: data.idHuyen,
                            diaChi: data.diaChi,
                        },
                    }"
                    button
                    detail
                >
                    <ion-label>Địa chỉ thường trú</ion-label>
                    <ion-label slot="end">
                        {{ data.diaChiThuongTru }}</ion-label
                    >
                </ion-item>
                <ion-item>
                    <ion-label>Năm bắt đầu hành nghề</ion-label>
                    <ion-label slot="end">
                        {{ data.namBatDauHanhNghe }}</ion-label
                    >
                </ion-item>
                <ion-item>
                    <ion-label>Số GPLX</ion-label>
                    <ion-label slot="end">{{ data.soGPLX }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>Hạng bằng</ion-label>
                    <ion-label slot="end">{{ data.hangBang }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>Ngày bắt đầu hiệu lực</ion-label>
                    <ion-label slot="end">{{
                        data.ngayBatDauHieuLuc
                            ? moment(data.ngayBatDauHieuLuc).format(
                                  "DD/MM/yyyy"
                              )
                            : null
                    }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>Thời hạn hiệu lực</ion-label>
                    <ion-label slot="end">{{
                        data.thoiHanHieuLuc
                            ? moment(data.thoiHanHieuLuc).format("DD/MM/yyyy")
                            : null
                    }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>Cơ quan cấp</ion-label>
                    <ion-label slot="end">{{ data.coQuanCap }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>Ngày cấp</ion-label>
                    <ion-label slot="end">{{
                        data.ngayCap
                            ? moment(data.ngayCap).format("DD/MM/yyyy")
                            : null
                    }}</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>

        <!-- <ion-footer>
            <div class="container-xs white--bg">
                <ion-button
                    @click="$router.push('/Ca-Nhan/thay-doi-mat-khau')"
                    color="primary"
                    fill="outline"
                    expand="block"
                >
                    Thay đổi mật khẩu
                </ion-button>
            </div>
        </ion-footer> -->
        <!-- <ion-nav :root="ChuThichVue"></ion-nav> -->
        <!-- ====================================Popup Thông báo ========================================= -->
        <PopupVue
            height="38%"
            :dialog="dialogThongBao"
            title=""
            :useButtonLeft="false"
            @close="
                () => {
                    dialogThongBao = false;
                }
            "
        >
            <template #content
                ><ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>
        <!-- </div> -->
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import PopupVue from "../../../src/components/_Common/Popup";
import ThongBaoVue from "../../../src/components/_Common/ThongBao";
import TaiKhoan from "../../../class/TaiKhoan";
import ChuThichVue from "../../components/ChuThichNgang";
import {
    IonPage,
    IonList,
    IonItem,
    IonContent,
    IonTitle,
    IonButton,
    IonToolbar,
    IonLabel,
    IonHeader,
    IonFooter,
    IonRow,
    onIonViewWillEnter,
} from "@ionic/vue";
export default {
    components: {
        ThanhTieuDeVue,
        ThongBaoVue,
        PopupVue,
        ChuThichVue,
        IonPage,
        IonList,
        IonItem,
        IonContent,
        IonTitle,
        IonButton,
        IonToolbar,
        IonLabel,
        IonFooter,
        IonHeader,
        IonRow,
    },
    props: {},
    data() {
        return {
            dialogThongBao: false,
            data: new TaiKhoan().get(),
            url: "",
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    methods: {
        async getAnh() {
            // try {
            //     await navigator.getUserMedia(
            //         {
            //             video: {
            //                 facingMode: "environment",
            //             },
            //         },
            //         function (srcObject) {
            //             ((v) => ((v.srcObject = srcObject), v.play()))(
            //                 document.querySelector("video#rearCam")
            //             );
            //         },
            //         console.error
            //     );
            // } catch (error) {
            //     console.error("rearCam", error);
            // }
        },
        async getData() {
            try {
                let rs = await new TaiKhoan().layThongTinCaNhan();
                if (!rs) {
                    throw new Error();
                }
                if (!rs.Data.status) {
                    // return this.thongBao(
                    //     "Error",
                    //     "Lỗi kết nối đến máy chủ",
                    //     rs.Data.message
                    // );
                } else {
                    this.data = rs.Data.data;
                }
            } catch (error) {
                // this.thongBaoLoi();
            }
            this.$stopLoading;
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        thongBaoLoi(message, log) {
            this.thongBao(
                "Error",
                message || "Đã xảy ra lỗi. Vui lòng thử lại"
            );
            console.log(
                "%c log ",
                "color: white; background-color: #95B46A",
                "=========>",
                log
            );
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.getData();
        });
    },
    mounted() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
.anh-dai-dien-style {
    width: 105px;
    height: 105px;
    border-radius: 50%;
    border: 1px solid $border;
    position: relative;
    overflow: hidden;
}
.anh-dai-dien-style:active {
    background-color: $border;
}
.divider {
    border: 0.5px solid $border;
    height: 4px;
    background-color: #e0e0e0;
}
.item-style {
    border-bottom: 1px solid $border;
    color: $text;
}
.item-style:active {
    background-color: $border;
}
.khong-icon {
    padding: 11px 0px 11px 0px;
}
.doimatkhau-style {
    position: sticky;
    bottom: 0;
    background-color: $background;
}
</style>