<template>
    <div
        :style="`height:${
            !drawer ? '36px' : '250px'
        };transition: height 0.2s;position:relative;`"
        class="shadow white--bg"
        @click="drawer = !drawer"
    >
        <div
            :class="`${
                !drawer ? 'row align-center' : 'column pl-4'
            } justify-center full-height`"
        >
            <!-- <div v-for="(item, index) in data.KieuCho" :key="index">
                <div class="row align-center pb-1 px-1">
                    <div
                        class="row justify-center align-center"
                        style="width: 28px; height: 28px"
                    >
                        {{ item.kyHieu }}
                    </div>
                    <div class="grow pl-2" v-if="drawer">
                        {{ item.tenKieuCho || "" }}
                    </div>
                </div>
            </div> -->
            <div
                v-for="(item, index) in data.DanhSachTrangThaiCho"
                :key="index"
            >
                <div
                    :class="`row align-center ${
                        !drawer ? 'pb-0' : 'pb-3'
                    } px-1`"
                >
                    <div
                        class="
                            item-trangthaicho
                            row
                            justify-center
                            align-center
                        "
                        :style="`background-color:${item.maMau};border-radius:4px;color:${item.idTrangThai == $t('trangThai.Trong') ? 'black':'white'}`"
                    >
                        {{ item.soLuong }}
                    </div>
                    <div class="grow pl-2" v-if="drawer">
                        {{ item.tenTrangThai || "" }}
                    </div>
                </div>
            </div>
        </div>
        <i
            :class="`mdi mdi-${!drawer ? 'chevron-down' : 'chevron-up'}`"
            style="position: absolute; right: 10px; top: 3px"
        ></i>
    </div>
    <div
        v-if="drawer"
        style="z-index: 8; width: 100vh; height: 100vh; position: fixed"
        @click="drawer = false"
    ></div>
</template>
<script>
import { mapGetters } from "vuex";
import { ChoNgoi } from "../../class/PhuongTien";
import { ref, reactive } from "vue";
export default {
    setup() {
        let drawer = ref(false);
        let data = reactive({ DanhSachTrangThaiCho: [], KieuCho: [] });
        return { drawer, data };
    },
    computed: {
        ...mapGetters("Ve", ["chuyenDiGanDay"]),
    },
    methods: {
        async getData() {
            //Lấy ds kiểu chỗ
            let DanhSachKieuCho = await new ChoNgoi().layDanhSachKieuCho();
            this.data.KieuCho = DanhSachKieuCho || [];
            //Lấy ds trạng thái chỗ
            let DanhSachTrangThaiCho =
                await new ChoNgoi().layDanhSachTrangThaiCho(
                    this.chuyenDiGanDay.guidChuyenDi
                );
            this.data.DanhSachTrangThaiCho = DanhSachTrangThaiCho || [];
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
<style lang="scss" scoped>
.item-trangthaicho {
    border: 1px solid $border;
    
    width: 28px;
    height: 28px;
}
.chuthich-style {
    height: 90%;
    position: absolute;
    left: 6px;
    top: 8%;
    border-radius: 20px;
    background-color: white;
    z-index: 1;
}
</style>
<style scoped>
>>> .nut-chuthich-style.dx-button-has-text {
    min-width: unset !important;
}
>>> .nut-chuthich-style .dx-button-content {
    padding: 6px 6px !important;
}
>>> .nut-chuthich-style.nut-trangthai span {
    color: white;
}
</style>